import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../../components/Typography/Typography";
import MainLayout from "../../components/mainlayout";
import {
  mobileViewBreakpoint,
  extraLargeDesktopViewBreakpoint
} from "../../components/helper";

import Summary from "../../components/CaseStudy/Summary/Summary";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },

  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));
const Article = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "casestudy/bannerdakarrally.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Supplying and running technical buses for Dakar Rally"}
      h1
      banner={
        <StaticImage
          src="../../assets/images/casestudy/bannerdakarrally.jpg"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Dakar Rally" }]}
      metaTitle="Dakar Rally Case Study"
      metaDescription="Check out how our team of transportation experts delivered the event’s critical fleet of technical support vehicles."
      shareImage={data.bannerImage.childImageSharp.fixed.src}
    >
      <div className={classes.mainContainer}>
        <div className={classes.caseStudyContainer}>
          <Typography customVariant={"h2Bold"}>Dakar rally</Typography>

          <Typography customVariant={"h5Regular"}>
            The 2020 Dakar Rally was the first to be held in the UAE. Our team
            had just two months to deliver the event’s critical fleet of
            technical support vehicles.
          </Typography>
        </div>
        <Summary
          title={"Overview"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/CASE-STUDY-DAKAR-OVERVIEW.jpg"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              MOTUS | ONE planned, procured, fitted, and operated the 2020 Dakar
              Rally technical-bus fleet. This involved retrofitting a fleet of
              motor coaches to serve as media logistics vehicles, accommodation
              units, and admin centers. Our staff and buses were deployed and
              moved across the rally’s entire 10-stage, 5,000 km route.
            </>
          }
        />

        <Summary
          title={"Our engagement"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/CASE-STUDY-DAKAR-OUTCOMES.jpg"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              <ul>
                <li>Complete vehicle retrofitting </li>
                <li>Training 65+ plus drivers and 30+ support staff </li>
                <li>
                  Logistics planning for route optimization, fuelling, and food
                </li>
                <li>
                  Vehicle engineering and technology providers to satisfy client
                  requirements
                </li>
              </ul>
            </>
          }
        />

        <Summary
          title={"Outcomes"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/CASE-STUDY-DAKAR-LEGACY.jpg"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              We sent teams in advance to drive the entire 5,000 km route for
              GPS tagging, locating resources, and identifying emergency
              procedures. We then created a production line outside Jeddah to
              strip and rebuild 12 coaches as state-of-the-art technical buses.
            </>
          }
        />

        <Summary
          title={"Legacy"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/dakar3.tif"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              We’re building a strong track record as the supplier of choice for
              critical transport and logistics services in the world of
              high-profile motorsport. This engagement is further proof of our
              ability to plan and deliver complex projects quickly while
              maintaining rigorous standards of technical capability.
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
